import {useLazyQuery} from '@apollo/client';
import clsx from 'clsx';
import {
    ExportMutationsDataExcelDocument,
    ExcelTemplate,
    ExportPlaylistDataExcelDocument,
} from '../../../graphql-types';
import {DownloadIcon} from '../../core/icons';
import forceDownload from '../../../helpers/files/forceDownload';
import {LoadingIndicator, Tooltip} from '../../core';
import toastify from '../../../helpers/toast/toastify';
import * as styles from './exportDownload.module.css';

interface IExportDownloadProps {
    mutationIds?: string[],
    playlistId?: string,
    label: string,
    description: string,
    template?: string,
    columnIds?: string[],
    disabled?: boolean,
    disabledTooltipText?: string,
}

const ExportDownload = ({
    label,
    description,
    template,
    columnIds,
    mutationIds,
    playlistId,
    disabled,
    disabledTooltipText,
}: IExportDownloadProps) => {
    const [getExcelUrl, {loading}] = useLazyQuery(
        playlistId ? ExportPlaylistDataExcelDocument : ExportMutationsDataExcelDocument,
        {
            fetchPolicy: 'no-cache',
        },
    );
    const isDisabled = disabled || loading;

    const handleClick = async () => {
        const variables: any = {};

        if (mutationIds) {
            variables.mutationIds = mutationIds;
        }

        if (playlistId) {
            variables.playlistId = playlistId;
        }

        if (template) {
            variables.template = template as ExcelTemplate;
        }

        if (columnIds) {
            variables.columnIds = columnIds;
        }

        const dataObject = {
            videos: 'exportMutationsDataExcel',
            playlist: 'exportPlaylistDataExcel',
        };

        await getExcelUrl({
            variables,
            onCompleted: (data: any) => {
                const url = data[playlistId ? dataObject.playlist : dataObject.videos].downloadUrl;

                if (!url!) {
                    toastify({
                        type: 'error',
                        text: 'There was an error on data export',
                    });

                    return;
                }

                forceDownload(url);

                toastify({
                    text: 'Excel created and data exported',
                    type: 'success',
                });
            },
            onError: err => {
                toastify({
                    type: 'error',
                    text: `There was an error on data export: ${err.message}`,
                });
            },
        });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.label}>
                <span>{label}</span>
            </div>
            <Tooltip
                text={disabledTooltipText || ''}
                disabled={!disabledTooltipText || (disabledTooltipText !== undefined && !disabled)}
            >
                <button
                    className={clsx(
                        styles.download,
                        isDisabled && styles.disabled,
                    )}
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    {loading && (
                        <LoadingIndicator/>
                    )}
                    {!loading && (
                        <DownloadIcon/>
                    )}
                </button>
            </Tooltip>
            <span className={styles.description}>
                {description}
            </span>
        </div>
    );
};

export default ExportDownload;
